import React, { useState, useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import { useTrackVisibility } from "react-intersection-observer-hook";

const Container = styled.main`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: calc((100% - 280px) - 10%);
  margin: 0px 10% 0px auto;

  ${media.lessThan("medium")`
    width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 10px;
  `}
`;

const Case = styled(motion.div)`
  position: relative;
  cursor: pointer;

  ${media.lessThan("medium")`
    height: 100vw !important;
  `}

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 300px;
  }

  :nth-child(6n + 1),
  :nth-child(6n + 2) {
    height: 23.75vw;
  }

  :nth-child(6n + 2) {
    grid-column-start: 3;
    grid-column-end: span col3-start;
  }

  :nth-child(6n + 3) {
    height: 47.39vw;
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  :nth-child(6n + 4) {
    height: 23.75vw;
    grid-column-start: 3;
  }

  :nth-child(6n + 5) {
    height: 23.75vw;
    grid-column-start: 1;
  }

  :nth-child(6n + 6) {
    height: 47.39vw;
    grid-column-start: 2;
    grid-column-end: span 3;
  }

  ${media.lessThan("medium")`
    grid-column-start: 1 !important;
  `}
`;

const Hover = styled(motion.div)`
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background: rgba(0, 0, 0, 0.55);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
`;

const Title = styled(motion.h1)`
  font-size: 24px;
  letter-spacing: 2px;
  font-family: "Krona One", sans-serif !important;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 18px;
  text-align: center;
`;

const Services = styled(motion.span)`
  font-size: 16px;
  font-weight: 300;
  color: #e3e3e3;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
`;

const ImageBackground = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.055;
  z-index: -1;
`;

const CaseCard = ({ item, index, filter }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [alreadyVisible, setAlreadyVisible] = useState(false);
  const [ref, { isVisible }] = useTrackVisibility();

  useEffect(() => {
    if (isVisible) setAlreadyVisible(true);
  }, [isVisible]);

  return (
    <Case
      ref={ref}
      key={`${index}_${item.node.id}`}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      initial={{ opacity: 0, y: 20 }}
      animate={{
        opacity: alreadyVisible || isVisible ? 1 : 0,
        y: alreadyVisible || isVisible ? 0 : 20,
        transition: { delay: 0.2, bounce: 0.19, mass: 3000 },
      }}
      exit={{
        opacity: 0,
        y: -20,
        transition: { delay: 0.05 * index, bounce: 0.19, mass: 3000 },
      }}
    >
      <AnimatePresence>
        {isHovered && (
          <ImageBackground
            initial={{ opacity: 0 }}
            animate={{
              opacity: 0.05,
            }}
            exit={{ opacity: 0 }}
            transition={{ type: "tween", duration: 1.4 }}
          >
            <Img
              fluid={item.node.cover.fluid}
              key={item.node.cover.fluid.src}
              alt={`${item.node.title} capa`}
            />
          </ImageBackground>
        )}
      </AnimatePresence>

      <Link to={`/cases/${item.node.slug}/`}>
        <Img
          fluid={item.node.cover.fluid}
          key={item.node.cover.fluid.src}
          alt={item.node.title}
        />
        <AnimatePresence>
          {isHovered && (
            <Hover
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Title
                initial={{ opacity: 0, y: 10 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.2,
                    bounce: 0,
                  },
                }}
                exit={{
                  y: -10,
                  opacity: 0,
                  transition: { bounce: 0.5 },
                }}
              >
                {item.node.title}
              </Title>
              <Services
                initial={{ opacity: 0, y: 10 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.3, bounce: 0.5 },
                }}
                exit={{
                  y: -10,
                  opacity: 0,
                  transition: { delay: 0.1, bounce: 0.5 },
                }}
              >
                {item?.node?.services.map((item) => item.title).join(" - ")}
              </Services>
            </Hover>
          )}
        </AnimatePresence>
      </Link>
    </Case>
  );
};

const CasesGrid = ({ items = [], filter = "Home" }) => {
  return (
    <Container>
      <AnimatePresence exitBeforeEnter>
        {items.map((item, index) => (
          <CaseCard
            item={item}
            index={index}
            filter={filter}
            key={`${filter}_${index}_${item.node.id}`}
          />
        ))}
      </AnimatePresence>
    </Container>
  );
};

export default CasesGrid;
