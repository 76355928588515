import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import media from 'styled-media-query';

import CasesGrid from '../components/Cases';
import Menu from '../components/Menu';
import { Page, RevealWord } from '../components';

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  min-height: 100vh;
  padding: 100px 0;
  position: relative;
  z-index: 2;
`;

const Title = styled.h1`
  font-size: 60px;
  font-family: 'Krona One';
  text-transform: uppercase;
  font-weight: 100;
  margin-bottom: 60px;
  width: 100%;
  letter-spacing: 9px;
  text-align: center;
  margin-top: 64px;
  margin-bottom: 128px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  ${media.lessThan('medium')`
    font-size: 32px;
    margin-bottom: 32px;
  `}
`;

const CasesPage = ({ data, location }) => {
  const query = new URLSearchParams(location.search);
  const urlFilter = query.get('filter');
  const [filter, setFilter] = useState(urlFilter || 'Todos');
  const [services] = useState(data?.allContentfulServices.edges);

  const filteredCases = useMemo(() => {
    if (filter) {
      if (filter === 'Todos') return data?.allContentfulCases?.edges;

      return data?.allContentfulCases?.edges.filter((item) => {
        return item.node.services.find((i) => i.title === filter);
      });
    }
    return data?.allContentfulCases?.edges.map((item) => item);
  }, [filter]);

  const filters = useMemo(() => {
    const serv = services.map(({ node }, index) => ({
      id: index + 1,
      title: node.title,
      href: undefined,
      onClick: () => setFilter(node.title),
    }));

    return [
      {
        id: 0,
        title: 'Todos',
        onClick: () => setFilter('Todos'),
      },
      ...serv,
    ];
  }, [services]);

  return (
    <Page title="Cases" description="Página cases do site Lab721">
      <Menu
        items={filters}
        selectedItem={filters.find((item) => item.title === filter)}
      />
      <Section id="cases">
        <Title>
          <RevealWord delay={1.2}>Cases</RevealWord>
        </Title>
        <CasesGrid items={filteredCases} filter={filter} />
      </Section>
    </Page>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulServices {
      edges {
        node {
          id
          title
        }
      }
    }
    allContentfulCases {
      edges {
        node {
          id
          title
          slug
          description {
            raw
          }
          services {
            id
            title
          }
          cover {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default CasesPage;
